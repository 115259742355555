import { Controller } from "stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

export default class extends Controller
  initialize: ->
    $.get(@data.get('url')).done (response) =>
      @the_calendar = new Calendar @element, {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin]
        minTime: '07:30:00'
        maxTime: '22:30:00'
        defaultView: 'dayGridMonth'
        navLinks: true
        editable: false
        allDaySlot: false
        slotLabelFormat: {
          hour: 'numeric'
          minute: '2-digit'
          omitZeroMinute: true
          omitCommas: true
          hour12: true
          meridiem: 'short'
        }
        eventLimit: true
        events: response
        locale: 'es'
        timeZone: 'America/Bogota'
        nowIndicator: true
        header: {
          left: 'prev,next today'
          center: 'title'
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        }
        buttonText: {
          today: 'Hoy'
          month: 'Mes'
          week: 'Semanas'
          day: 'Día'
          list: 'Lista'
        }
      }
      @the_calendar.render()
  disconnect: ->
    @the_calendar.destroy()
