import {Controller} from "stimulus"
import AxiosRequest from "../../utils/axios-request"

export default class extends Controller
  submit: (eve) ->
    eve.preventDefault()
    product = document.getElementById('the-product-form')
    service = document.getElementById('the-service-form')
    price = document.getElementById('the-price-form')
    @env = @.data.get 'env'
    @item = @.data.get 'item'
    @key = @.data.get 'key'
    @jwt = @.data.get 'jwt'
    if service || product
      final_price = parseInt document.getElementById('item_available_price_attributes_price').value.replace(/[^0-9.]/g, '')
      purchase_price = parseInt document.getElementById('item_purchase_price')?.value.replace(/[^0-9.]/g, '')
      purchase_tax_id = parseInt document.getElementById('item_purchase_price_tax_id').value
      extracted_tax_id = parseInt document.getElementById('item_available_price_attributes_tax_fare_id').value
    else
      discount = parseInt document.getElementById('public_price_discount_attributes_in_percentage').value
      price_ahora = parseInt document.getElementById('public_price_discount_attributes_in_price').value.replace(/[^0-9.]/g, '')
      tmp_price = parseInt document.getElementById('public_price_available_price_attributes_price').value.replace(/[^0-9.]/g, '')
      if discount >= 0
        final_price = tmp_price - (tmp_price * (discount / 100))
      else if price_ahora > 0
        final_price = price_ahora
      purchase_price = parseInt(@.data.get('pprice'))
      purchase_tax_id = parseInt(@.data.get('ptaxid'))
      extracted_tax_id = parseInt(@.data.get('tax'))

    if JSON.parse @env
      router_api = 'http://dev.allkryon.com:3006'
    else
      router_api = 'https://api.kryon.digital'
    authenticity_token = $('[name="csrf-token"]')[0].content
    final_data = {
      security_key: @key,
      iditem: @item,
      final_price: final_price,
      purchase_price: purchase_price,
      purchase_tax_id: purchase_tax_id,
      extracted_tax_id: extracted_tax_id,
      authenticity_token
    }
    request = $.ajax(
      {
        url: "#{router_api}/api/v1/item_commission",
        data: JSON.stringify(final_data),
        method: 'post',
        headers: { 'Authorization': "Bearer #{@jwt}" }
        processData: false
        contentType: "application/json"
      }
    )
    request.done (data) ->
      if service || product
        document.getElementById('item_return_cause').value = data.cause
        document.getElementById('item_return_profit').value = data.profit
      if price
        document.getElementById('public_price_return_cause').value = data.cause
        document.getElementById('public_price_return_profit').value = data.profit
      if product
        product.submit()
      else if service
        service.submit()
      else if price
        price.submit()
    request.fail (data) ->
      Swal.fire({
        icon: 'error'
        title: 'Error'
        text: data.responseJSON.message
        showConfirmButton: false
        timer: 2000
      }).then ->
        if product
          product.submit()
        else if service
          service.submit()
        else if price
          price.submit()
