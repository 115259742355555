import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import UiKit from 'uikit'

export default class extends Controller {
  static targets = ['dummy', 'input', 'image', 'loader', 'progress', 'wrapper'];

  /* -------------------------- ANCHOR CICLO DE VIDA -------------------------- */
  connect() {
    console.log('connect');
    this.getActiveStorageUrl();
    console.info('iniciando drop area', this.initDropArea());
  }
  /* ----------------------------- ANCHOR PROCESOS ---------------------------- */
  initDropArea() {
    UiKit.upload(this.wrapperTarget, {
      beforeSend: (environment) => {
        for (let value of environment.data.values()) this.uploadFile(value)
        environment.cancel()
      },
      loadStart: (eve) => {
        this.showLoading();
        this.showProgress();
        this.setProgress({ max: eve.total, value: eve.loaded });
      },
      progress: (eve) => this.setProgress({ max: eve.total, value: eve.loaded }),
      loadEnd: (eve) => {
        this.setProgress({ max: eve.total, value: eve.loaded });
        this.showProgress(false);
      }
    });
  }
  /* -------------------------------------------------------------------------- */
  uploadFile(file) {
    const upload = new DirectUpload(file, this.url);
    upload.create((error, blob) => {
      if (error) {
        alert('Error al subir la imagen: ', error);
      } else {
        this.inputTarget.type = 'hidden';
        this.inputTarget.value = blob.signed_id;

        const reader = new FileReader;
        reader.readAsDataURL(file)
        reader.onload = eve => {
          this.showDummy(false);
          this.imageTarget.removeAttribute('hidden');
          this.imageTarget.src = eve.target.result;
        }
        reader.onerror = error => console.error(error);
      }
    })
  }
  /* --------------------------- ANCHOR CARPINTERÍA --------------------------- */
  showDummy(should_show = true) {
    if (should_show) {
      this.dummyTarget.removeAttribute('hidden');
    } else {
      this.dummyTarget.setAttribute('hidden', true);
    }
  }
  /* -------------------------------------------------------------------------- */
  showProgress(should_show = true) {
    if (should_show) {
      this.progressTarget.removeAttribute('hidden');
    } else {
      this.progressTarget.setAttribute('hidden', true);
    }
  }
  /* -------------------------------------------------------------------------- */
  setProgress({ max, value }) {
    this.progressTarget.max = max;
    this.progressTarget.value = value;
  }
  /* -------------------------------------------------------------------------- */
  showLoading(should_show = true) {
    if (should_show) {
      this.loaderTarget.removeAttribute('hidden');
    } else {
      this.loaderTarget.setAttribute('hidden', true);
    }
  }
  /* ----------------------------- ANCHOR GETTERS ----------------------------- */
  getActiveStorageUrl() {
    this.url = this.inputTarget.dataset.directUploadUrl;
    // delete this.inputTarget.dataset.directUploadUrl;
  }
  /* --------------------------------------------------------------- JHON F. M. */
}
