import {Controller} from "stimulus"
import AxiosRequest from "../../utils/axios-request"

export default class extends Controller
  general: ->
    @uid = @.data.get 'uid'
    @date = @.data.get 'date'
    @amount = @.data.get 'amount'
    AxiosRequest("/check_state/#{@uid}/accounts/settle", {customer: @uid, date: @date}, 'post').then ({data}) =>
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: data.message,
        timer: 3000
      }).then ->
        location.reload()
